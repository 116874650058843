import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0287d44a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "category" }
const _hoisted_2 = { class: "category--title flex items-center justify-between" }
const _hoisted_3 = { class: "total" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_category_table = _resolveComponent("category-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", null, _toDisplayString(_ctx.currentName.name), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.currentText), 1)
    ]),
    _createVNode(_component_category_table, {
      portfolioId: _ctx.portfolioId,
      modules: _ctx.category.modules,
      core: !_ctx.category.optional,
      course: _ctx.course
    }, null, 8, ["portfolioId", "modules", "core", "course"])
  ]))
}