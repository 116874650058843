import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dc1cc704"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["data-id"]
const _hoisted_2 = { class: "portfolio--name" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_portfolio_categories = _resolveComponent("portfolio-categories")!
  const _component_portfolio_diversification = _resolveComponent("portfolio-diversification")!

  return (_ctx.portfolio.id !== 'expertPortfolio')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "portfolio",
        "data-id": _ctx.portfolio.id
      }, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.portfolioTitle), 1),
        _createVNode(_component_portfolio_categories, {
          portfolioId: _ctx.portfolio.id,
          portfolioName: _ctx.portfolioName,
          target: _ctx.target,
          course: _ctx.course,
          categories: _ctx.getCategories
        }, null, 8, ["portfolioId", "portfolioName", "target", "course", "categories"]),
        _createVNode(_component_portfolio_diversification, {
          course: _ctx.course,
          portfolio: _ctx.portfolio
        }, null, 8, ["course", "portfolio"])
      ], 8, _hoisted_1))
    : _createCommentVNode("", true)
}