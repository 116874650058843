
import { defineComponent, PropType, inject } from 'vue-demi';
import calculation, { currency, numberSpaces } from '../calculation';
import correctDescription from '@/utils/correctEnd';
import { HELP_LIST } from '@/store/commonDatas';
import { Questionnaire } from '@/interfaces/dto/questionnaire';

export default defineComponent({
  name: 'QuestionnaireTableCommon',
  props: {
    target: {
      type: Object as PropType<Questionnaire.QTarget>,
      required: true
    },
    course: {
      type: String,
      required: true,
    },
  },
  setup() {
    const targetCurrencyId = inject('targetCurrencyId');
    const targetCurrencySign = inject('targetCurrencySign');
    return { targetCurrencyId, targetCurrencySign }
  },
  data() {
    return {
      elements: [],
      onHelp: false,
      helperList: HELP_LIST
    }
  },
  computed: {
    correctHelper() {
      return this.helperList.find(h => h.id === this.course);
    },
    income() {
      return Number(calculation.getCorrectCurrency(this.target.type.sections[0].modules, String(this.targetCurrencyId)))
    },
    term() {
      return this.target.type.sections[1].modules[0].data;
    },
    currentTerm() {
      return calculation.currentTerm(this.term);
    },
    correctInflation() {
      return this.target.main.data.inflation / 100;
    },
    FV() {
      const _fv = this.target.type.sections[2].modules[0].data;
      const target = this.target.main.data;
      const fv = currency(_fv.amount, _fv.currency_id, target.currency_id);
      const name = isNaN(fv) ? 'Не выбрано' : `${numberSpaces(fv)} ${target.currency_sign}`.trim()
      return name;
    },
    correctTerm() {
      const term = `${this.term.term} ${correctDescription(this.term)}`;
      if (!term) return 'Не выбран';
      return term.trim() || 'Не выбран';
    },
    riskPortfolio() {
      const risk = this.target.type.sections.find(s => s.default === 'portfolio-field');
      if (!risk) return 'Не выбран';
      return risk.modules[0].data.portfolio || 'Не выбран';
    },
  },
})
