
import { defineComponent, inject, PropType } from 'vue-demi';
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import { getCorrectPercentBalance, getCorrectPercentCurrencies } from '../../calculation';
import { dynamicsObject } from '@/interfaces';

export default defineComponent({
  name: 'PortfolioDiversification',
  props: {
    portfolio: {
      type: Object as PropType<Questionnaire.QTargetPortfolio>,
      required: true
    },
    course: {
      type: String,
      required: true
    }
  },
  setup() {
    const targetCurrencyId: string = inject('targetCurrencyId') || '';
    const targetCurrencySign: string = inject('targetCurrencySign') || '';
    return {
      targetCurrencySign,
      targetCurrencyId
    }
  },
  data() {
    return {
      mixedAssets: [],
      defaults: {
        first: 'Процент считается по сумме всех инструментов',
        second: 'Процент считается по сумме всех инструментов ядра портфеля'
      }
    }
  },
  created() {
    this.getMixedAssets();
  },
  methods: {
    async getMixedAssets() {
      const result = await this.API.common.getMixeds();
      this.mixedAssets = result.data;
    },
    setPercents(percents: dynamicsObject) {
      this.portfolio.sections[0].modules[0].data.core = percents['0'] ? percents['0'].replace('%', '') : NaN;
      this.portfolio.sections[0].modules[0].data.tactic = percents['1'] ? percents['1'].replace('%', '') : NaN;
    }
  },
  computed: {
    percentBalance() {
      const coreArray = this.portfolio.sections[1].modules.map(m => m.data).filter(m => m.price);
      const tacticArray = this.portfolio.sections[2].modules.map(m => m.data).filter(m => m.price);
      const percents = getCorrectPercentBalance(this.targetCurrencyId, this.course, this.mixedAssets, coreArray, tacticArray);
      this.setPercents(percents);
      return percents;
    },
    percentTypes() {
      const coreArray = this.portfolio.sections[1].modules.map(m => m.data).filter(m => m.price);
      return getCorrectPercentBalance(
        this.targetCurrencyId,
        this.course,
        this.mixedAssets,
        coreArray.filter(i => i[`class_${this.course}_id`] === 'stock'),
        coreArray.filter(i => i[`class_${this.course}_id`] === 'bond'),
        coreArray.filter(i => i[`class_${this.course}_id`] === 'alternative')
      );
    },
    percentCurrencies() {
      const coreArray = this.portfolio.sections[1].modules;
      const tacticArray = this.portfolio.sections[2].modules;
      const array = coreArray.concat(tacticArray).map(m => m.data).filter(m => m.price);
      return getCorrectPercentCurrencies(this.targetCurrencyId, array, this.course);
    }
  }
})
