import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6c3b39b3"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_questionnaire_common = _resolveComponent("questionnaire-common")!
  const _component_questionnaire_portfolios = _resolveComponent("questionnaire-portfolios")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_questionnaire_common, {
      target: _ctx.target,
      course: _ctx.course
    }, null, 8, ["target", "course"]),
    _createVNode(_component_questionnaire_portfolios, {
      course: _ctx.course,
      target: _ctx.target
    }, null, 8, ["course", "target"])
  ]))
}