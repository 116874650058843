import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "portfolios" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_questionnaire_portfolio = _resolveComponent("questionnaire-portfolio")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.target.portfolios, (portfolio, name) => {
      return (_openBlock(), _createBlock(_component_questionnaire_portfolio, {
        key: portfolio.id,
        portfolioName: name,
        portfolio: portfolio,
        target: _ctx.target,
        course: _ctx.course
      }, null, 8, ["portfolioName", "portfolio", "target", "course"]))
    }), 128))
  ]))
}