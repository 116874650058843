
import { defineComponent, inject, PropType } from "vue-demi";
import { Questionnaire } from "@/interfaces/dto/questionnaire";

export default defineComponent({
  name: "QuestionnaireTableHeader",
  emits: ['selectTarget', 'addStudentTarget', 'removeStudentTarget'],
  props: {
    targets: {
      type: Array as PropType<Array<Questionnaire.QTarget>>,
      required: true,
    },
    insurance: {
      type: Object,
      required: true
    },
    selectedTargetId: {
      type: Number,
      required: true
    },
    pivot: {
      type: Boolean,
      default: false
    },
  },
  setup() {
    const status = inject('status');
    return { status };
  },
  computed: {
    insuranceStatus() {
      let status = Object.values(this.insurance).find(v => v);
      return status ? 'Изменить' : 'Добавить';
    }
  },
  methods: {
    removeTarget() {
      this.emitter.emit('removeStudentTarget', this.selectedTargetId);
    }
  }
});
