import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f56e335a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex items-center justify-between" }
const _hoisted_2 = { class: "targets flex" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 0,
  class: "actions flex"
}
const _hoisted_5 = {
  key: 1,
  class: "btn btn--border btn--disabled"
}
const _hoisted_6 = {
  key: 3,
  class: "btn btn--green btn--disabled"
}
const _hoisted_7 = {
  key: 5,
  class: "btn btn--green btn--disabled"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.targets, (target) => {
        return (_openBlock(), _createElementBlock("div", {
          class: _normalizeClass(["target", { selected: _ctx.selectedTargetId === target.id }]),
          key: target.id,
          onClick: ($event: any) => (_ctx.$emit('selectTarget', target))
        }, _toDisplayString(target.name), 11, _hoisted_3))
      }), 128))
    ]),
    (!_ctx.pivot)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.status)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.commit('createModal', { id: 'insurance-edit', data: _ctx.insurance }))),
                class: "btn btn--border"
              }, _toDisplayString(_ctx.insuranceStatus) + " страховые продукты", 1))
            : (_openBlock(), _createElementBlock("button", _hoisted_5, _toDisplayString(_ctx.insuranceStatus) + " страховые продукты", 1)),
          (_ctx.status && _ctx.targets.length < 3)
            ? (_openBlock(), _createElementBlock("button", {
                key: 2,
                onClick: _cache[1] || (_cache[1] = ($event: any) => {_ctx.$store.commit('addTarget', 'STUDENT'); _ctx.$emit('addStudentTarget')}),
                class: "btn btn--green"
              }, "Добавить цель"))
            : (_ctx.targets.length < 3)
              ? (_openBlock(), _createElementBlock("button", _hoisted_6, "Добавить цель"))
              : _createCommentVNode("", true),
          (_ctx.status && _ctx.selectedTargetId !== 1)
            ? (_openBlock(), _createElementBlock("button", {
                key: 4,
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeTarget && _ctx.removeTarget(...args))),
                class: "btn btn--green"
              }, "Удалить цель"))
            : (_ctx.selectedTargetId !== 1)
              ? (_openBlock(), _createElementBlock("button", _hoisted_7, "Удалить цель"))
              : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}