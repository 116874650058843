
import { computed, defineComponent, inject, PropType } from 'vue-demi';
import portfolioCategory from "./category.vue";
import { currency, getCorrectInstrumentsCurrency } from '../../calculation';
import { Questionnaire } from '@/interfaces/dto/questionnaire';
import { Files } from '@element-plus/icons';
import { COURSES_ENUM, ROLES_ENUM } from '@/utils/enums';

export default defineComponent({
  name: "PortfolioCategories",
  props: {
    categories: {
      type: Array as PropType<Array<Questionnaire.QSection>>,
      required: true,
    },
    target: {
      type: Object,
      required: true,
    },
    portfolioId: {
      type: String,
      required: true,
    },
    portfolioName: {
      type: [String, Number],
      required: true,
    },
    course: {
      type: String,
      required: true,
    },
  },
  setup() {
    const targetCurrencyId = inject('targetCurrencyId');
    const targetCurrencySign = inject('targetCurrencyId');
    const status = inject('status');
    return {
      targetCurrencyId,
      targetCurrencySign,
      status
    }
  },
  provide() {
    return {
      total: computed(() => this.total),
      totalInstruments: computed(() => this.totalInstruments),
      targetTotal: computed(() => {
        return currency(this.target.type.sections[2].modules[0].data.amount, this.target.type.sections[2].modules[0].data.currency_id, this.target.main.data.currency_id)
      }),
    }
  },
  methods: {
    uploadFiles(portfolioName: string | number) {
      this.emitter.emit(`changeTacticFile`, { portfolioName, files: (this.$refs['tactic-' + this.portfolioId] as any).files });
      (this.$refs['tactic-' + this.portfolioId] as any).files = null;
    }
  },
  computed: {
    totalInstruments() {
      const arr = this.categories.map(c => c.modules).reduce((acc, arr) => acc.concat(arr));
      return arr.map(m => m.data).filter(m => m.price);
    },
    total() {
      const arr = this.categories.map(c => c.modules).reduce((acc, arr) => acc.concat(arr));
      const array = arr.map(m => m.data).filter(m => m.price);
      return getCorrectInstrumentsCurrency(array, String(this.targetCurrencyId), this.course);
    },
    onTacticFiles() {
      return this.$store.getters.role === ROLES_ENUM.STUDENT && this.course === COURSES_ENUM.TWO && this.status;
    },
    totalPercent() {
      const arr = this.categories.map(c => c.modules).reduce((acc, arr) => acc.concat(arr));
      return Math.ceil10(arr.map(m => m.data).reduce((acc, m) => acc + Number(m.percent), 0), -2);
    },
    leftTotalPercent() {
      return Math.ceil10(100 - this.totalPercent <= 0 ? 0 : 100 - this.totalPercent, -2);
    }
  },
  components: {
    portfolioCategory, Files
  },
});
