
import { defineComponent, PropType, inject } from 'vue-demi';
import { mapGetters } from 'vuex';
import { currectSignCurrency, getCorrectInstrumentsCurrency, numberSpaces, getCurrency } from '../../../calculation';
import { Instrument } from '@/interfaces/dto/instrument';
import { dynamicsObject, valueof } from '@/interfaces';
import { COURSES_ENUM } from '@/utils/enums';

export default defineComponent({
  name: "TableElement",
  props: {
    instruments: {
      type: Array as PropType<Array<Instrument.Dto>>,
      required: true,
    },
    type: {
      type: Object,
    },
    portfolioId: {
      type: String,
      required: true,
    },
    length: {
      type: Number,
      required: true
    },
    course: {
      type: String as PropType<valueof<COURSES_ENUM>>,
      required: true,
    },
  },
  setup() {
    const targetCurrencyId = inject('targetCurrencyId');
    const targetId = inject('targetId');
    const targetCurrencySign = inject('targetCurrencySign');
    const status = inject('status');
    const total: string = inject('total') || '';
    const totalInstruments = inject('totalInstruments');
    const targetTotal = inject('targetTotal');
    return {
      targetCurrencyId, targetId, targetCurrencySign, status, total, totalInstruments, targetTotal
    }
  },
  data() {
    return {
      lengths: [
        { id: 'one', type: true, number: 25 },
        { id: 'one', type: false, number: 10 },
        { id: 'two', type: true, number: 58 },
        { id: 'two', type: false, number: 5 },
      ]
    }
  },
  computed: {
    ...mapGetters(['user']),
    totalType() {
      return getCorrectInstrumentsCurrency(this.instruments, String(this.targetCurrencyId), this.course).replace(/ /g, '');
    },
    currentText() {
      return `
        ${ this.totalType } 
        ${ this.targetCurrencySign || '' }
        (${Math.ceil10(((Number(this.totalType) / Number(this.total.replace(/ /g, ''))) * 100), -1) || 0} %)
      `;
    }
  },
  methods: {
    currectSignCurrency,
    dublicateInstrument(instrument: Instrument.Dto) {
      const price = getCurrency(instrument, String(this.targetCurrencyId), this.course);
      const percent = Math.ceil10((price / Number(this.targetTotal)) * 100, -2);
      this.$store.commit('dublicateStudentInstrument', { instrument, percent, targetId: this.targetId, type: this.type });
    },
    correctLength(type: any) {
      const length = this.lengths.find(l => l.id === this.course && l.type === !!type);
      if (!length) return 30;
      return length.number;
    },
    currentAmount(instrument: Instrument.Dto) {
      const lot = (this.course === COURSES_ENUM.TWO && instrument.lot) ? instrument.lot : 1;
      return instrument.price * lot * instrument.number_papers;
    },
    correctPercent(instrument: Instrument.Dto) {
      const price = getCurrency(instrument, String(this.targetCurrencyId), this.course);
      if (!price) return price;
      return Math.ceil10((price / Number(this.total.replace(/ /g, ''))) * 100, -1);
    },
    modal(id: string, data: dynamicsObject | null) {
      this.$store.commit('createModal', { id, data })
    },
    numberSpaces
  },
});
