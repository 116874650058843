import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0617a47e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "questionnaire--container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_questionnaire_header = _resolveComponent("questionnaire-header")!
  const _component_questionnaire_container = _resolveComponent("questionnaire-container")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_ctx.course === 'one')
      ? (_openBlock(), _createBlock(_component_questionnaire_header, {
          key: 0,
          targets: _ctx.targets,
          insurance: _ctx.insurance,
          selectedTargetId: _ctx.selectedTarget.id,
          pivot: _ctx.pivot,
          onSelectTarget: _ctx.selectTarget,
          onAddStudentTarget: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addStudentTarget')))
        }, null, 8, ["targets", "insurance", "selectedTargetId", "pivot", "onSelectTarget"]))
      : _createCommentVNode("", true),
    _createVNode(_component_questionnaire_container, {
      course: _ctx.course,
      target: _ctx.currentTarget
    }, null, 8, ["course", "target"])
  ]))
}